.quiz-title {
    text-transform: uppercase;
    font-size: 5.5vw;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    color: black;
    text-decoration:none;
    padding: 0 3vw 0 3vw;
  }
  
  .quiz-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left:2vw;
    padding-right:2vw;
  }

  .quiz-divider {
    margin: 10px;
    width: 100%;
    background-color: grey;
  }