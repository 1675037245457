@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&family=Poppins:wght@200;300&display=swap');


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar{
  background: hsla(209, 100%, 77%, 1);
}

::-webkit-scrollbar-thumb {
  background: rgb(60, 93, 126);
  border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: #000;
}

.home {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

