.quiz-question-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Poppins", sans-serif;
}

.quiz-subtitle{
    font-size: 25px;
    border: 1px solid black;
    box-shadow: 4px 4px 2px black;
    padding: 5px 10px;
}

.quiz-info{
    width:100%;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    margin:10px;
    font-weight:600;
}