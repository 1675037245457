.article > video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.article {
    font-family: 'PT Sans', sans-serif;
    padding-bottom: 300px;
    position: relative;
    justify-content: center; /* center items horizontally */
    align-items: center; /* center items vertically */
  }
  
  .article > h1 {
    text-align: center;
    color:white;
  }

  .article-text {
    background: black;
    font-family: 'PT Sans', sans-serif;
    border: 1px;
    margin: auto;
    border-color: white;
    width: 70vw;
    border-style: solid;
    padding: 20px 20px 10px;
    text-align: center;
    transform: translateY(50px);
  }
  
  .article-text > img {
    display: block;
    padding-left: 100px;
    pointer-events: none;
  }

  .article-text > p {
    font-size: 16px;
    text-align: left;
    color:white;
    font-family: sans-serif;
    line-height:25px;
  }

  .article-text > p > a{
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: hsla(209, 100%, 77%, 1);
    font-family: sans-serif;
    line-height:25px;
  }

  .article-text > p > a:hover{
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: salmon;
    transition: all 0.2s ease-out;
    font-family: sans-serif;
    line-height:25px;
  }

  .article > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}
