.hero-container > video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}
.hero-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: insert 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    font-family: 'PT Sans', sans-serif;
}

.hero-container > h1 {
    color: #fff;
    font-size: 9vw;
    margin-top: -100px;
    text-shadow: 5px 5px 10px blue;
    transition: all 0.3s ease-out;
}

.hero-container > h1:hover {
    font-size: 12.5vw;
    text-shadow: 5px 5px 10px red;
    transition: all 0.3s ease-out;
}

.hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: sans-serif;
}

.hero-btns{
    margin-top:32px;
}

.hero-btns .btn {
    margin:6px;
    font-family: 'PT Sans', sans-serif;
}

.fa-play-circle {
    margin-left:4px;
}

.hero-container > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

@media (max-width: 768px) {
    video {
    display: none;
  }
}

