.hero-container-social > video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.footer-container-social {
    background: none;

    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#000000", endColorstr="#8BC7FF", GradientType=1 );
    padding: 2rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    font-family: 'PT Sans', sans-serif;
}

.hero-container-social {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: insert 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.hero-container-social > h1 {
    color: #fff;
    font-size: 6vw;
    margin-top: -100px;
    text-shadow: 5px 5px 10px blue;
    transition: all 0.3s ease-out;
    font-family: 'PT Sans', sans-serif;
}

.hero-container-social > h1:hover {
    font-size: 8vw;
    text-shadow: 5px 5px 10px red;
    transition: all 0.3s ease-out;
}

.fa-play-circle {
    margin-left: 4px;
}

.footer-links-social{
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-link-items-social a {
    border: 1px solid #fff;
    border-radius:2px;
    padding:0.5rem;
    transition: all 0.2s linear;
    margin-bottom: 8px;
    text-decoration:none;
    height: 45px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-link-items-social{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin:16px;
    text-align:left;
    width:160px;
    box-sizing:border-box;
    transition: all 0.3s linear;
}

.footer-link-items-social a:hover{
    transition: all 0.3s linear;
    background-color:white;
    color:black;
}

@media screen and (max-width: 960px) {
    .hero-container>h1 {
        font-size: 70x;
        margin-top: -150px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container>h1 {
        font-size: 50px;
        margin-top: -100px;
    }

    .hero-container>p {
        font-size: 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}

.hero-container-social > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

@media (max-width: 768px) {
    video {
    display: none;
  }
}

