.footer-container {
    background: -webkit-linear-gradient(270deg, hsla(0, 0%, 0%, 1) 0%, hsla(209, 100%, 77%, 1) 100%, hsla(0, 0%, 0%, 1) 100%);

    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#000000", endColorstr="#8BC7FF", GradientType=1 );
    padding: 2rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    font-family: 'PT Sans', sans-serif;
}

.footer-container > section {
    margin-top: 1rem;
}

.footer-subscription{
    flex-direction:column;
    margin-bottom:24px;
    padding:24px;
    color:#fff;
}

.footer-subscription > p {
    font-family: sans-serif;
}

.footer-subscription-heading{
    margin-top: 5px;
    font-size:32px;
}

.footer-subscription-text{
    margin-bottom:24px;
    font-size:20px;
}

.footer-input{
    padding:8px 20px;
    border-radius:2px;
    margin-right: 10px;
    outline:none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.footer-links{
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-link-wrapper{
    display: flex;
}

.footer-link-items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin:16px;
    text-align:left;
    width:160px;
    box-sizing:border-box;
    transition: all 0.3s linear;
}

.footer-link-items a:hover{
    transition: all 0.3s linear;
    background-color:white;
    color:black;
}


.footer-link-items a {
    border: 1px solid #fff;
    border-radius:2px;
    padding:0.5rem;
    transition: all 0.2s linear;
    margin-bottom: 8px;
    text-decoration:none;
    height: 45px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-input::placeholder {
    color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
    color: #fff;
    font-size: 24px;
}

.social-media {
    max-width: 1000px;
    width: 100%;
}

.social-media-wrap {
    display: flex;
    justify-content: center;
    align-items:center;
    width:90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
}

.social-icons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
}

.social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-self: center;
    margin-bottom: 16px;
}

.website-rights{
    color: #fff;
    margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
    .footer-links {
        padding-top:2rem;
    }
    .footer-input {
        width: 100%;
    }

    .btn {
        width: 100%;
    }

    .footer-link-wrapper{
        flex-direction: column;
    }

    .social-media-wrap{
        flex-direction: column;
    }
}